import React from 'react';

export default class ContactSection extends React.Component {
    render() {
        return (
            <section className="section">
              <div className="container container--md">
                <form name="contactForm" id="contactForm" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                  <div className="sr-only">
                    <label id="honeypot-label" htmlFor="honeypot">Don't fill this out if you're human:</label>
                    <input aria-labelledby="honeypot-label" id="honeypot" name="bot-field" />
                  </div>
                  <input aria-labelledby="honeypot-label" type="hidden" name="form-name" value="contactForm" />
                  <div className="form-group">
                    <label id="name-label" htmlFor="name">Name</label>
                    <input aria-labelledby="name-label" type="text" name="name" id="name" placeholder="Ihr Name" required />
                  </div>
                  <div className="form-group">
                    <label id="email-label" htmlFor="email">Email</label>
                    <input aria-labelledby="email-label" type="email" name="email" id="email" placeholder="Ihre E-mail" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="subject">Betreff</label>
                    <div className="form-select-wrap">
                      <select name="subject" id="subject">
                        <option value="">Bitte wählen</option>
                        <option value="Business Inquiries">Anfrage</option>
                        <option value="Sponsorship">Angebot</option>
                        <option value="Other">Andere</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label id="message-label" htmlFor="message">Ihre Nachricht</label>
                    <textarea aria-labelledby="message-label" name="message" id="message" rows="5" placeholder="Ihre Nachricht" />
                  </div>
                  <div className="form-group form-checkbox">
                    <input aria-labelledby="consent-label" type="checkbox" name="consent" id="consent" />
                    <label id="consent-label" htmlFor="consent">Ich verstehe, dass dieses Formular meine übermittelten Informationen speichert, damit ich kontaktiert werden kann.</label>
                  </div>
                  <div className="form-submit">
                    <button type="submit" className="button">Nachricht schicken</button>
                  </div>
                </form>
              </div>
            </section>
        );
    }
}
